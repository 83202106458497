import React, { useState, useRef, useEffect } from "react"
import styled from "styled-components"
import { Swiper } from "swiper/dist/js/swiper.esm"
import MARKET_DATA from "./data"
import media from "../../styles/media"
import ImageWrapper from "../elements/imageWrapper"
import { FormContainer } from "../Apply"
import { Formik } from "formik"
import { Helmet } from "react-helmet"
import HubspotForm from "../HubspotForm"

const Container = styled.div`
  .swiper-container {
    height: 275px;
    .swiper-wrapper {
      .swiper-slide {
        .gatsby-image-wrapper {
          height: 275px;
        }
        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
    }
  }
  ${media.tablet`
    padding: 0 0 var(--gutter-s) var(--gutter-l);
    .swiper-container {
      /* height: 300px; */
      .swiper-wrapper {
        .swiper-slide {
          .gatsby-image-wrapper {
            width: 100%
            height: 100%
          }
          width: 56vw;
          &:last-child {
            margin-right: 45vw;
          }
        }
      }
    }
  `}
  ${media.laptop`
      .swiper-container {
        height: 396px;
      }
  `}
`

const Heading = styled.h1`
  font-weight: 600;
  text-align: center;
  font-size: 3rem;
  ${media.tablet`
    position: relative;
    text-align: left;
    transform: translateY(35%);
    font-size: 5.5rem;
    z-index: 2;
  `}
`
const SwiperNav = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 3rem;
  span {
    margin: 5px 2px;
    font-size: 0.9em;
  }
  .arrows {
    span {
      padding: 3rem 0;
      &.disabled {
        pointer-events: none;
        opacity: 0.25;
      }
      &#left-arrow {
        padding-right: 2.5rem;
      }
    }
  }
  ${media.tablet`
    padding: 0;
    padding-top: 0.5rem;
    width: 56vw;
  `}
`

const Content = styled.div`
  padding: var(--gutter-s);
  p {
    padding-bottom: 15px;
  }
  ${media.tablet`
    .text-grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
    p {
      font-size: 1.2rem;
      padding: var(--gutter-s);
    }
    .grid-2 {
      margin-top: 25%;
    }
  `}
`

const Slide = styled.div`
  position: relative;
  .img-container {
    width: auto;
    height: 100%;
    overflow: hidden;
    img {
      width: auto;
      height: 100%;
      object-fit: cover;
      object-position: center center;
      transition: transform 2500ms ease-out !important;
      transform: ${props => (props.zoom ? `scale(1.1)` : `scale(1)`)};
    }
    &:hover {
      img {
        transition: transform 2500ms ease-out !important;
        transform: scale(1.1);
      }
    }
  }
`
const HubspotFormContainer = styled.div`
  background-color: black;
  hbspt-form .hs-form .hs-form-field {
    &&& label {
      color: #fff;
    }
  }
`

const Market = () => {
  const [zoom, setZoom] = useState(false)

  const renderMarketData = () => {
    return MARKET_DATA.map((market, index) => {
      return (
        <Slide key={index} className="swiper-slide" zoom={zoom}>
          <div className="img-container">
            <ImageWrapper src={market.img} />
          </div>
        </Slide>
      )
    })
  }

  const swiperElement = useRef(null)
  const swiper = useRef(null)

  const [activeSlide, setActiveSlide] = useState(0)
  const [showFormSuccess, setFormSuccess] = useState(false)

  useEffect(() => {
    swiper.current = new Swiper(swiperElement.current, {
      speed: 1000,
      freeMode: true,
      freeModeSticky: true,
      breakpointsInverse: true,
      touchStartPreventDefault: false,
      slidesPerView: 1,
      spaceBetween: 30,
      centeredSlides: true,
      breakpoints: {
        768: {
          slidesPerView: "auto",
          centeredSlides: false,
        },
      },
      on: {
        transitionEnd: () => {
          if (swiper.current) {
            setActiveSlide(swiper.current.activeIndex)
          }
        },
        touchStart: () => {
          setZoom(true)
        },
        touchEnd: () => {
          setZoom(false)
        },
      },
    })
  }, [])

  const slideNext = () => {
    if (swiper.current) {
      swiper.current.slideNext()
    }
  }
  const slidePrev = () => {
    if (swiper.current) {
      swiper.current.slidePrev()
    }
  }

  return (
    <>
      <Container>
        <Heading>marketplace</Heading>
        <div ref={swiperElement} className="swiper-container slider-swipe">
          <div className="swiper-wrapper">{renderMarketData()}</div>
        </div>

        <SwiperNav>
          <div>
            <span>
              {activeSlide < 9 ? "0" : null}
              {activeSlide + 1}
            </span>
            <span className="divider">&#8213;</span>
            <span>
              {MARKET_DATA.length < 9 ? "0" : null}
              {MARKET_DATA.length}
            </span>
          </div>
          <div className="arrows">
            <span
              onClick={slidePrev}
              id="left-arrow"
              className={activeSlide == 0 ? "disabled" : "mouse-link"}
            >
              &#8592;
            </span>
            <span
              onClick={slideNext}
              className={
                activeSlide + 1 == MARKET_DATA.length
                  ? "disabled"
                  : "mouse-link"
              }
            >
              &#8594;
            </span>
          </div>
        </SwiperNav>

        <Content>
          <div className="text-grid">
            <div>
              <p>
                We would like to build a platform at SAITC where like-minded
                makers can have an opportunity to directly access a community of
                value for their brand and products. In South Africa this is a
                limited opportunity. We really hope we can grow this aspect of
                our show. If you are a maker and you feel our community would
                love your authentic, high quality, local handmade goods, please
                reach out.
              </p>
              <p>
                We serve amazing local coffee & bakes in the market, so please
                come check it out and help us to support locals!
              </p>
            </div>
          </div>
        </Content>
      </Container>

      <FormContainer>
        <p className="form-text">
          We have limited stands available. If you would like to apply for one,
          complete the form below and we&apos;ll get back to you.
        </p>
        <HubspotForm formScriptID="06f81ffb-0b76-49c8-a09e-d18b89bf755e" />
      </FormContainer>
    </>
  )
}

export default Market
