import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/SEO"

import Market from "../components/Market"

const MarketplacePage = () => {
  return (
    <Layout>
      <SEO title="Marketplace" />
      <Market />
    </Layout>
  )
}

export default MarketplacePage
