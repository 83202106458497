const MARKET_DATA = [
  {
    img: "market/MarketplaceImages/1.jpg",
  },
  {
    img: "market/MarketplaceImages/2.jpg",
  },
  {
    img: "market/MarketplaceImages/3.jpg",
  },
  {
    img: "market/MarketplaceImages/4.jpg",
  },
  {
    img: "market/MarketplaceImages/5.jpg",
  },
  {
    img: "market/MarketplaceImages/7.jpg",
  },
  {
    img: "market/MarketplaceImages/8.jpg",
  },
]

export default MARKET_DATA
